body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(to bottom, #7fb4ff, #ff9a7b);
    overflow: hidden; /* Prevent vertical scroll */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden; /* Prevent vertical scroll within the container */
}

.login-content {
    display: flex;
    width: 100%;
    max-width: 800px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: 85%;
    max-height: 80vh; /* Ensure the content doesn't exceed the viewport height */
}

.signup-image {
    flex: 1;
    background: url('../assets/img/signup-bg.PNG') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    height: 100%;
    position: relative;
}

.signup-image::before {
    content: '';
    position: absolute;
    top: 10%; /* Adjust as needed to position the image slightly above center */
    width: 80%;
    height: 80%;
    background: url('../assets/img/signup-bg.PNG') no-repeat center center;
    background-size: cover;
    z-index: -1;
}

.login-image {
    flex: 1;
    background: url('../assets/img/login-bg.PNG') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    height: 100%;
    position: relative;
}

.login-image::before {
    content: '';
    position: absolute;
    top: 10%; /* Adjust as needed to position the image slightly above center */
    width: 80%;
    height: 80%;
    background: url('../assets/img/login-bg.PNG') no-repeat center center;
    background-size: cover;
    z-index: -1;
}


.login-form {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-logo {
    width: 150px;
    margin-bottom: 20px;
}

h4 {
    margin: 0;
    color: #333;
}

form {
    display: flex;
    flex-direction: column;
}

.input-group {
    position: relative;
    margin-bottom: 15px;
}

.input-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #888;
    pointer-events: none; /* Prevents the icon from blocking input field interactions */
    padding-bottom: 10px;
}

input[type="email"],
input[type="password"],
input[type="text"] {
    padding: 10px;
    padding-left: 35px; /* Ensure there's enough space for the icon */
    margin-bottom: 7px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1em;
    width: 100%; /* Ensure inputs take full width */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.eye-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
    pointer-events: auto; /* Ensure the button is clickable */
}

.login-button {
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    width: 100%; /* Ensure button takes full width */
}

.signup-link {
    color: #ff7e5f;
    text-decoration: none;
    text-align: center;
}

/* Sticky Footer */
footer {
    background: white;
    width: 100%;
    padding: 1px;
    text-align: center;
    color: #333;
    font-size: 0.8em;
    position: sticky;
    bottom: 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .login-content {
        flex-direction: column;
        height: auto;
        max-height: none; /* Allow height to adjust on smaller screens */
    }

    .signup-image {
        display: none; /* Hide the image on smaller screens */
    }

    .login-form {
        padding: 20px;
    }
}
.error{
    display: flex;
    color: red;
    font-size: 11px;
}
.succes-page{
    text-align: center;
    font-size:14px;
    color:#333
}
.underline {
    width: 61px;
    height: 6px;
    background: #3C009D;
    border-radius: 9px;
}
.text {
    color: #3C009D;
    font-size: 20px; /* Adjusted font size for smaller screens */
    font-weight: 700;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #db343c;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 8px;
  }
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



