.dashboard-container {
  display: flex;
  height: 100vh;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #E8EDF1;
  transition: margin-left 0.3s ease-in-out;
}

.sidebar {
  width: 250px;
  background-color: #E85C3A;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-open .main-content {
  margin-left: 270px;
}

.sidebar-closed .main-content {
  margin-left: 0;
}

@media (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .sidebar-open .main-content {
    margin-left: 170px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 150px;
  }

  .sidebar-open .main-content {
    margin-left: 170px;
  }
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.dashboard-logo {
  width: 140px;
  padding-top: 10px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 16px;
  /* Adjusted font size for professionalism */
  cursor: pointer;
}

nav ul li a {
  color: white;
  text-decoration: none;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

nav ul li:hover {
  text-decoration: underline;
}

.toggle-button {
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: #E85C3A;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1100;
  border-radius: 4px;
  font-size: 20px;
  /* Adjusting icon size */
}

@media (max-width: 768px) {
  .toggle-button {
    left: 10px;
    padding: 8px;
    font-size: 18px;
    /* Adjusting icon size */
  }
}

@media (max-width: 480px) {
  .toggle-button {
    left: 5px;
    padding: 6px;
    font-size: 16px;
    /* Adjusting icon size */
  }
}

.dashboard {
  padding: 30px;
  background-color: #E8EDF1;
  flex-grow: 1;

  height: 100%;
  overflow-y: auto;
  /* Enable vertical scrolling */
  scrollbar-width: thin;
  /* For Firefox, makes scrollbar thinner */
  -ms-overflow-style: scrollbar;

}

h2 {
  color: #E85C3A;
  margin-bottom: 30px;
}

.dashboard-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.card {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
}

.card:last-child {
  margin-right: 0;
}

/* Media query for mobile view (max-width: 768px) */
@media (max-width: 768px) {
  .dashboard-cards {
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Ensure the items take up the full width */
  }

  .card {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add space between stacked cards */
    padding: 5px;
  }

  .card:last-child {
    margin-bottom: 0; /* Remove bottom margin on the last card */
  }
}

.card:last-child {
  margin-right: 0;
}

.wallet-balance {
  background-color: #4A7DA6;
  color: white;
}

.bank-account {
  background-color: #333;
  color: white;
}

.balance {
  font-size: 24px;
  margin: 10px 0;
}

.hide-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-switch {
  width: 40px;
  height: 20px;
  /* background-color: #ddd; */
  border-radius: 10px;
  position: relative;
}

.toggle {
  width: 18px;
  height: 18px;
  background-color: #E85C3A;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 2px;
}

.transaction-history {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  overflow-x: auto; /* Horizontal scroll */
  overflow-y: auto; /* Vertical scroll */
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 100px; /* Ensures the table has a minimum width, allowing scrolling */
}

table th,
table td {
  padding: 10px;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Optional: Style the scrollbar for better user experience */
.transaction-history::-webkit-scrollbar {
  height: 8px;
}

.transaction-history::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 4px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .transaction-history {
    padding: 10px;
    width:350px;
  }

  table th,
  table td {
    padding: 8px;
    font-size: 14px; /* Smaller font size for mobile screens */
  }
}

/***actions-container***/
/* Actions container styles */
.actions-container {
  /* display: flex;
    overflow-x: auto;
    white-space: nowrap; 
    padding: 10px;
    scrollbar-width: thin;
    -ms-overflow-style: scrollbar;  */
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;

}

.actions-container::-webkit-scrollbar {
  height: 8px;
  /* Height of the scrollbar */
  background-color: #f0f0f0;
  /* Background of the scrollbar track */
}

.actions-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded scrollbar thumb */
}

.actions-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
  /* Darker color on hover */
}

.action-item {
  display: inline-flex;
  /* Ensure items are in a row and support horizontal scrolling */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  padding: 4px;
  background-color: #E85C3A;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  min-width: 50px;
  transition: background-color 0.3s ease;
}

.action-item:hover {
  background-color: #ddd;
  color: #E85C3A;
}

.action-text {
  margin-top: 5px;
  font-size: 12px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .actions-container {
    padding: 5px;
  }

  .action-item {
    margin: 0 5px;
    padding: 8px;
  }

  .action-text {
    font-size: 10px;
  }
}

@media (min-width: 769px) {
  .actions-container {
    padding: 5px;
  }

  .action-item {
    margin: 0 15px;
    padding: 15px;
  }

  .action-text {
    font-size: 16px;
  }
}

/* Style for the wallet balance card */
.card.wallet-balance {
  position: relative;
}

/* Style for the balance visibility toggle */
.hide-balance {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hide-balance span {
  margin-right: 10px;
}

.toggle-switch {
  display: flex;
  align-items: center;
}

.toggle {
  width: 30px;
  height: 15px;
  border-radius: 15px;
  /* background: #ddd; */
  position: relative;
  transition: background 0.3s;
}

.toggle.visible {
  background: #4caf50; /* Color when balance is visible */
}

.toggle.hidden {
  background: #f44336; /* Color when balance is hidden */
}

.toggle::after {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.3s;
}

.toggle.visible::after {
  left: 15px; /* Move the toggle to the right when visible */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-info {
  margin: 0 15px;
  font-size: 16px;
  color: #333;
}

.pagination-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-button:disabled,
.pagination-button.disabled {
  background-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background-color: #0056b3;
}

.pagination-button:not(:disabled):active {
  background-color: #00408b;
}

