.header {
  background-color: white;
  padding: 15px 30px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between; /* Ensures the .wel is on the left */
  align-items: center;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wel {
  padding-left:10px;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  flex-grow: 1;
}

.user-info {
  display: flex;
  align-items: center;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-size: 16px;
  font-weight: bold;
}

.dropdown-arrow {
  font-size: 16px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.dropdown-arrow.rotate {
  transform: rotate(180deg);
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 50px;
  right: 0px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1200;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.dropdown-menu ul li a,
.logout-button {
  color: #333;
  text-decoration: none;
  margin-left: 10px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.logout-button {
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #d32f2f;
}
