.kycForm {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    width: 100%;
    margin: 12px auto;
    box-sizing: border-box;
}

.kycForm .form-group {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.kycForm{
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto; /* Enables horizontal scroll */
    overflow-y: auto; /* Enables vertical scroll */
    padding: 0 10px; /* Add some horizontal padding to avoid clipping */
    background-color: #fff;
    border-radius: 10px; /* Add some border radius for aesthetics */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-right: 20px;
    max-height: 400px; /* Set a max height to trigger vertical scroll */
}
.kycForm label {
    font-weight: bold;
    margin-bottom: 0px;
}

.kycForm input[type="text"], input[type="file"], input[type="date"],
.kycForm select,
.kycForm textarea {
    width: 100%;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.kycForm textarea {
    resize: vertical;
}

.kycForm .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-bottom: 30px;
}

.kycForm .submit-button:hover {
    background-color: #0056b3;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
    .kycForm {
        width: 95%;
        padding: 15px;
    }

    .kycForm .submit-button {
        padding: 12px 0;
    }
}

@media (max-width: 480px) {
    .kycForm {
        width: 100%;
        padding: 10px;
    }

    .kycForm .submit-button {
        font-size: 14px;
        padding: 10px 0;
    }

    .kycForm input[type="text"],
    .kycForm select,
    .kycForm textarea {
        font-size: 14px;
    }
}

.page-content {
    margin: 0 auto;
    padding: 5px;
    /* text-align: center; */
    background-color: #fff;
    border-radius: 8px;
    max-width: 70%; /* Ensure it fits within the viewport */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.page-content h1 {
    font-size: 24px; /* Base font size */
    margin-bottom: 30px;
    color: #333;
}

/* Medium screens */
@media (max-width: 768px) {
    .page-content {
        padding: 15px; /* Adjust padding for smaller screens */
        max-width: 95%; /* Increase width to better fit the screen */
    }

    .page-content h1 {
        font-size: 20px; /* Reduce font size for smaller screens */
    }
}

/* Small screens */
@media (max-width: 480px) {
    .page-content {
        padding: 10px; /* Further adjust padding for very small screens */
        max-width: 100%; /* Full width for very small screens */
    }

    .page-content h1 {
        font-size: 18px; /* Smallest font size */
        margin-bottom: 15px; /* Adjust margin for better spacing */
    }
}

